const footerNavBar = {
  'footerNavBar-1': '© Pandito GmbH CH-270.4.008.394-2',
  'footerNavBar-2': '+41 77 409 1740 panditoicecream@gmail.com',
  'footerNavBar-3': 'Schaffhauserrheinweg 118, Basel. Opening hours',
  'footerNavBar-3-tooltip-1': 'Glacé-Ape',
  'footerNavBar-3-tooltip-2': 'Winter break',
  'footerNavBar-4': 'Im Westfeld 4, Basel. Opening hours',
  'footerNavBar-4-tooltip-1': 'Gelateria',
  'footerNavBar-4-tooltip-2': 'Monday: Closed',
  'footerNavBar-4-tooltip-3': 'Tuesday: Closed',
  'footerNavBar-4-tooltip-4': 'Wednesday: 12:00 - 19:00 h',
  'footerNavBar-4-tooltip-5': 'Thursday: 12:00 - 19:00 h',
  'footerNavBar-4-tooltip-6': 'Friday: 12:00 - 20:00 h',
  'footerNavBar-4-tooltip-7': 'Saturday: 11:00 - 20:00 h',
  'footerNavBar-4-tooltip-8': 'Sunday: 11:00 - 19:00 h',
  'footerNavBar-5': 'Legal notice / ',
  'footerNavBar-6': 'Privacy policy',
  'footerNavBar-7': 'Designed by Fede'
}

export default footerNavBar
